import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>DOVE SIAMO</h2>
      {/**
      <p>
        Sed lorem ipsum dolor sit amet et nullam consequat feugiat consequat
        magna adipiscing tempus etiam dolore veroeros. eget dapibus mauris. Cras
        aliquet, nisl ut viverra sollicitudin, ligula erat egestas velit, vitae
        tincidunt odio.
      </p>
      <ul className="actions">
        <li>
          <Link to="/generic" className="button">
            Learn More
          </Link>
        </li>
      </ul> */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.4248795938925!2d9.478422451668353!3d45.52165487899919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786b53c91e381f9%3A0xfb059668c73c2ce6!2sGIUVI%20SRL!5e0!3m2!1sit!2sit!4v1590519892062!5m2!1sit!2sit" width="100%" height="300" frameborder="0" allowfullscreen="" aria-hidden="false" title="mappa"></iframe>
    </section>
    <section>
      <h2>CONTATTI</h2>
      <dl className="alt">
        <dt>Indirizzo</dt>
        <dd>Via Il Caravaggio, 38 <br/> 20060 Pozzuolo M.na (MI) &bull; Italia</dd>
        <dt>Telefono</dt>
        <dd><a href="tel:+390295356673">(+39) 02 95 35 66 73</a></dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:info@giuvi.it">info@giuvi.it</a>
        </dd>
        <dt>P.IVA</dt>
        <dd>IT 07873720960</dd>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://www.linkedin.com/company/giuvi-srl/"
            className="icon fa-linkedin alt">
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/GIUVI.SRL/" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>
        {/* 
        <li>
          <a href="https://codebushi.com" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/codebushi/gatsby-starter-stellar"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-dribbble alt">
            <span className="label">Dribbble</span>
          </a>
        </li>
        */}
      </ul>
    </section>
    {/*
    <p className="copyright">
      &copy; Untitled. Design: <a href="https://html5up.net">HTML5 UP</a>.
    </p> 
    */}
  </footer>
)

export default Footer
